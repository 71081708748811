.error{
    min-height: calc(100vh - 34px);
}

.error__h4 {
	text-align: center;
	padding: 3em;
    color: rgb(51, 51, 51);
}

.error__a{
text-decoration: none;
cursor: pointer;
color: var(--grey);
}

.error__a:hover{
    color: var(--greenOpacity);
}