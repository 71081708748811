.section-title {
	position: relative;
	display: inline-block;
	padding: 2rem;
	text-align: center;
	margin: 0 auto;
	width: 52%;
	left: 50%;
	transform: translate(-50%);
	color: var(--greenOpacity);
}

.top,
.bottom {
	position: absolute;
	width: 50px;
	height: 50px;
}

.top {
	top: 0;
	border-top: 1px solid var(--greenOpacity);
}

.bottom {
	bottom: 0;
	border-bottom: 1px solid;
}

.leftBorder {
	left: 0;
	border-left: 1px solid;
}

.rightBorder {
	right: 0;
	border-right: 1px solid;
}

.section-p {
	font-size: 1.5rem;
	color: var(--grey);
}
.section-h2 {
	font-size: 3rem;
	margin-top: 0.5rem;
	text-transform: uppercase;
	color: var(--grey);
}
@media only screen and (max-width: 768px) {
	.section-title {
		width: 74%;
	}
	.section-p {
		font-size: 1rem;
	}
	.section-h2 {
		font-size: 2rem;
	}
}
@media only screen and (max-width: 300px) {
	.section-title {
		width: 90%;
	}
	.section-h2 {
		font-size: 1.5rem;
	}
}
