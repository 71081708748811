.gift__wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 5em 0.5em 1em;
	text-align: center;
}

.cennik__h3 {
	color: rgb(55, 55, 55);
	border-right: 2px solid var(--green);
	cursor: pointer;
}

.cennik__h3:hover .fas {
	color: var(--green);
}

.cennik__heading {
	position: relative;
	margin: 3em 2em 1.5em;
	padding: 1.5em 0;
	text-align: center;
	background-image: linear-gradient(
		rgba(123, 122, 122, 0.277),
		rgba(246, 246, 246, 0.358)
	);
	border-radius: 5%;
}

.cennik__heading::after {
	position: absolute;
	content: '';
	bottom: 0;
	left: 0;
	right: 0;
	height: 1.2em;
	margin: 0 auto;
	width: 30%;
	border-top: 2px solid var(--greenOpacity);
	z-index: 1;
}

.table {
	margin: 0 auto;
	width: 90%;
	border-color: var(--greyLight);
}

th,
td {
	border-width: 0.1em;
	font-size: 1.5em;
}

@media only screen and (max-width: 300px) {
	th,
	td {
		font-size: 1em;
	}
}

@media only screen and (min-width: 768px) {
	.cennik__h3 {
		white-space: nowrap;
		overflow: hidden;
		animation: animated-text 5s steps(60, end) 1s 1 normal both,
			blinkTextCursor 400ms;
	}

	@keyframes animated-text {
		from {
			width: 0;
		}

		to {
			width: 525px;
		}
	}

	@keyframes blinkTextCursor {
		from {
			border-right-color: var(--green);
		}

		to {
			border-right-color: transparent;
		}
	}
}