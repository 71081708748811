.containerIcon {
	padding: 2rem;
	background-color: var(--contactGrey);
	display: flex;
	align-items: center;
	gap: 2rem;
	border-radius: 8px;
	margin-bottom: 2rem;
}
.iconContact {
	background-color: var(--white);
	padding: 1.3rem;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
}
.infoContact{
	color:var(--white);
	font-size: 1.5rem;
}

@media only screen and (max-width: 300px) {
	.infoContact>p{
		font-size: 1rem;
	}
}
