.container {
	padding: 7rem 0.5rem;
}
.contactSection__wrapper {
	display: flex;
	gap: 5rem;
	margin-top: 7rem;
	justify-content: space-between;
	position: relative;
}
.contactSection__wrapper::after {
	position: absolute;
	content: '';
	width: 2px;
	height: 50%;
	background-color: var(--green);
	left: 50%;
	top: 30%;
	transform: translate(-50%, -50%);
}
.left {
	width: 100%;
	max-width: 500px;
}
.right {
	max-width: 500px;
	width: 100%;
	border-radius: 12px;
}
@media only screen and (max-width: 768px) {
	.contactSection__wrapper {
		flex-direction: column;
	}
	.contactSection__wrapper::after {
		/* display: none; */
		top: 47%;
		width: 80%;
		height: 2px;
	}
	.left,
	.right {
		max-width: 100%;
		padding: 4rem 2rem 2rem 2rem;
	}
}
