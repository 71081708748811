.search {
	display: flex;
	margin: 0 auto;
	width: 40%;
	margin-top: 2em;
	padding: 0.5em;
	font-size: 1.3em;
	background-color: transparent;
	border: 2px solid rgb(216, 216, 216);
	color: var(--grey);
}
.search::placeholder {
	color: var(--grey);
}
.list {
	padding-top: 6em;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 80px;
}
.boxItem {
	height: 100%;
	margin: 0 3em;
	padding: 2em 3em;
	position: relative;
	border: 1px solid transparent;
	border-radius: 8px;
	transition: border 0.5s;
	background-color: rgb(216, 216, 216);
	z-index: 5;
}

.boxItem:hover {
	border: 1px solid var(--green);
	z-index: 1;
}

.boxItem::before {
	position: absolute;
	content: '';
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border: 1px solid var(--green);
	border-radius: 8px;
	opacity: 0;
}

.boxItem:hover::before {
	animation: pulse 1s;
}

@keyframes pulse {
	from {
		transform: scale(1);
		opacity: 0.5;
	}

	to {
		transform: scale(1.4);
		opacity: 0;
	}
}

.fizyko__h3 {
	padding: 1em;
	text-align: center;
}

.fizyko__p {
	font-size: 1.2em;
}


@media screen and (max-width: 960px) {
	.list {
		grid-template-columns: repeat(1, 1fr);
		padding-left: 0;
	}
	.boxItem {
		margin: 0 auto;
		padding: 1em;
		width: 90%;
	}
	.fizyko__p {
		text-align: center;
	}
}

@media screen and (max-width: 300px) {
	.list{
		padding: 5em 0;
	}
	.search {
		width: 60%;
	}
	.boxItem {
		margin: 0 auto;
		width: 70%;
	}
	.fizyko__h3 {
		font-size: 1.2em;
	}
	
	.fizyko__p {
		font-size: 1em;
	}
	
}
