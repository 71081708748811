.hero_container {
	background-image: linear-gradient(
			rgba(106, 106, 106, 0.647),
			rgba(168, 168, 168, 0.848)
		),
		url('../assets/Image/human-body.png');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	padding: 6em 2em 2em 2em;
	height: 100vh;
	z-index: -1;
}

.hero_title {
	margin: 0 auto;
	text-align: center;
}

.hero_h1 {
	font-size: 3.2rem;
	color: var(--white);
	letter-spacing: 0.1em;
	text-shadow: 0px 2px 2px var(--green);
}

.hero_p {
	font-size: 1.8rem;
	color: var(--white);
}

.hero_desc {
	display: flex;
	justify-content: space-evenly;
	gap: 1em;
	padding-top: 8em;
	cursor: pointer;
	color: var(--white);
	letter-spacing: 0.1em;
}

/* .hero_h3:hover {
	border-bottom: 3px solid rgb(93, 177, 86);
	margin: -1px;
} */

.hero_desc-icon {
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-size: 1.5em;
}

.fa-angle-double-right,
.fa-angle-double-left {
	color: var(--green);
}
.hero_link {
	text-decoration: none;
}
.hero_arrow {
	margin-top: 8em;
	display: flex;
	align-items: center;
	justify-content: center;
}

.hero_icon {
	width: 4%;
	animation: bounce 2s infinite;
}

@keyframes bounce {
	0% {
		transform: scale(1, 1) translateY(0);
	}
	10% {
		transform: scale(1.1, 0.9) translateY(0);
	}
	30% {
		transform: scale(0.9, 1.1) translateY(-50px);
	}
	50% {
		transform: scale(1, 1) translateY(0);
	}
	57% {
		transform: scale(1, 1) translateY(-7px);
	}
	64% {
		transform: scale(1, 1) translateY(0);
	}
	100% {
		transform: scale(1, 1) translateY(0);
	}
}

.about__wrapper {
	margin: 0 auto;
	width: 80%;
	text-align: justify;
	padding-top: 4em;
	font-size: 1.5em;
}

.about__bold {
	font-weight: bold;
}

@media only screen and (max-width: 767px) {
	.about__wrapper {
		text-align: left;
		font-size: 1.3em;
	}

	.hero_desc{
		padding-top: 5em;
	}

	.hero_container {
		padding: 6em 0;
		height: 100vh;
	}

	.hero_h1 {
		font-size: 2rem;
	}

	.hero_p {
		font-size: 1rem;
	}

	.hero_h3 {
		font-size: 1.3rem;
	}

	.hero_h3-right {
		padding-bottom: 5em;
	}

	.hero_desc {
		display: block;
		position: relative;
		text-align: center;
		padding: 10em 0 0 0;
	}

	.hero_desc-icon {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0.1em;
	}

	.hero_icon {
		display: none;
	}
}

@media screen and (max-width: 300px) {
	.hero_container {
		padding: 3em 0;
	}
	.hero_desc {
		padding: 4em 0 0 0;
	}
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.hero_container {
		height: 100vh;
		padding: 12em 0 1em 0;
	}
	
	.hero_desc {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 10em;
		gap: 3em;
	}

	.hero_h3 {
		font-size: 1.9rem;
	}

	.hero_desc-icon {
		padding: 1em;
		font-size: 1.5em;
	}

	.hero_arrow {
		margin-top: 19em;
	}
	
	.hero_icon {
		width: 8%;
	}
}
