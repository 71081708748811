.elements__wrapper {
	margin: 0 auto;
	max-width: 1200px;
	width: 100%;
}
.elements {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(5, 1fr);
	gap: 2em;
	padding: 9em 2em 0;
}
.text-about {
	padding-bottom: 1em;
	font-size: 2em;
	text-transform: uppercase;
	color: var(--grey);
}
.lorem {
	font-size: 1.5em;
}
.elementIcon {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 8em;
	opacity: 80%;
}

.elements__icon {
	padding: 3em;
	color: var(--grey);
}

.elements__cards {
	padding: 3em;
}
span {
	background-color: rgba(94, 177, 86, 0.464);
	width: 20%;
	padding: 0.2em;
	border-radius: 10%;
}

.elements__cards--one {
	grid-column: 1/2;
}
.elements__cards--two {
	grid-column: 2/2;
	grid-row: 2/5;
}
.elements__cards--three {
	grid-column: 1/2;
	grid-row: 3/5;
}
.elements__cards--four {
	grid-column: 2/2;
	grid-row: 4/5;
}
.elements__cards--five {
	grid-column: 1/2;
	grid-row: 5/5;
}
.elements__cards--six {
	grid-column: 2/2;
}
.elements__cards--seven {
	grid-column: 1/2;
	grid-row: 2/5;
}
.elements__cards--eight {
	grid-column: 2/2;
	grid-row: 3/5;
}
.elements__cards--nine {
	grid-column: 1/2;
	grid-row: 4/5;
}
.elements__cards--ten {
	grid-column: 2/2;
	grid-row: 5/5;
}

@media only screen and (max-width: 768px) {
	.text-about {
		font-size: 1.5em;
		padding-bottom: 0.2em;
	}
	.lorem {
		font-size: 1.1em;
	}
	.elementIcon {
		font-size: 6em;
	}

	.elements {
		position: relative;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(10, 1fr);
		gap:0;
	}

	.elements__icon {
		padding: 3em;
	}

	.elements__cards {
		padding-top: 0;
	}
	.elements__cards--one {
		grid-column: 1/1;
		grid-row: 2/10;
	}
	.elements__cards--two {
		grid-column: 1/1;
		grid-row: 4/10;
		padding-top: 4em;
	}
	.elements__cards--three {
		grid-column: 1/1;
		grid-row: 6/10;
	}
	.elements__cards--four {
		grid-column: 1/1;
		grid-row: 8/10;
	}
	.elements__cards--five {
		grid-column: 1/1;
		grid-row: 10/10;
	}
	.elements__cards--six {
		grid-column: 1/1;
		grid-row: 1/10;
	}
	.elements__cards--seven {
		grid-column: 1/1;
		grid-row: 3/10;
		padding-top: 8em;
	}
	.elements__cards--eight {
		grid-column: 1/1;
		grid-row: 5/10;
	}
	.elements__cards--nine {
		grid-column: 1/1;
		grid-row: 7/10;
		padding-bottom: 4em;
	}
	.elements__cards--ten {
		grid-column: 1/1;
		grid-row: 9/10;
	}
}
@media only screen and (max-width: 300px) {
	.elementIcon {
		font-size: 4em;
	}
	.elements {
		padding:0;
	}
	.elements__cards--six {
		grid-column: 1/1;
		grid-row: 1/10;
		padding-top: 10em;
	}
	.elements__cards--seven {
		grid-column: 1/1;
		grid-row: 3/10;
		padding-top: 12em;
	}
}
