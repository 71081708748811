.imgWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}
.imgContact {
	min-height: 350px; 
	width: 95%;
	border-radius: 5%;
}

.map__heading {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 2.5rem;
	padding-bottom: 1em;
	margin-bottom: 1rem;
	color: var(--grey);
}

.map__link:hover {
	color: #242424;
}
@media only screen and (max-width: 768px) {
	.map__heading {
		font-size: 1.8rem;
	}
	.imgContact {
		min-height: 250px; 
	}
}
