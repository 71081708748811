.gift__wrapper {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}
.thumb {
	justify-content: flex-start;
	align-items: flex-start;
	margin: 6em 2em;
	width: 500px;
	height: 400px;
	perspective: 1000px;
}
.thumb a {
	display: block;
	width: 100%;
	height: 100%;
	background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
		url('../assets/Image/bon1.JPG');
	background-size: 0, cover;
	background-position: center;
	transform-style: preserve-3d;
	transition: all 0.5s;
	border-radius: 1%;
}
.thumb:hover a {
	transform: rotateX(80deg);
	transform-origin: bottom;
}
/*bottom surface */
.thumb a:after {
	content: '';
	position: absolute;
	left: 0;
	bottom: 0;
	width: 100%;
	height: 36px;
	background: inherit;
	background-size: cover, cover;
	background-position: bottom;
	transform: rotateX(90deg);
	transform-origin: bottom;
}
/*label style*/
.thumb a .gift__text {
	color: white;
	text-transform: uppercase;
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	text-align: center;
	transform: rotateX(-89.99deg);
	transform-origin: top;
	z-index: 1;
	background-color: transparent;
}
/*shadow*/
.thumb a:before {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	box-shadow: 0 0 100px 50px rgba(0, 0, 0, 0.5);
	transition: all 0.5s;
	opacity: 0.15;
	transform: rotateX(95deg) translateZ(-80px) scale(0.75);
	transform-origin: bottom;
}
.thumb:hover a:before {
	opacity: 1;
	box-shadow: 0 0 25px 25px rgba(0, 0, 0, 0.5);
	transform: rotateX(0) translateZ(-60px) scale(0.85);
}

.gift__desc {
	justify-content: flex-end;
	align-items: flex-end;
	width: 600px;
	text-align: left;
	font-size: 1.3em;
	letter-spacing: 0.01em;
}

.gift__light,
.gift__regular,
.gift__dark {
	background-color: transparent;
	font-weight: bold;
	color: rgba(172, 167, 113, 0.644);
}

@media only screen and (max-width: 767px) {
	.gift__wrapper {
		display: block;
		margin: 0 auto;
		width: 80%;
	}
	.thumb {
		margin: 3em 2em 6em 2em;
		width: 280px;
		height: 200px;
		margin: 3em auto 6em;
	}

	.gift__desc {
		width: 300px;
		text-align: left;
	}
}

@media screen and (max-width: 300px) {
	.thumb {
		width: 200px;
		height: 200px;
		margin: 1em auto 6em;
	}

	.thumb a .gift__text {
		font-size: 0.7em;
	}

	.gift__desc {
		font-size: 1em;
		width: auto;
		text-align: left;
	}
	.gift__p {
		display: block;
		font-size: 1em;
	}
}
