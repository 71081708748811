.terapia__h3 {
	text-align: center;
	padding: 5em 0 2em 0;
	color: var(--grey);
}

.terapia__p {
	font-size: 1.4em;
	padding: 2em 0 0.5em;
	text-align: center;
}

.terapia__bold {
	color: var(--green);
}

.terapia__lista {
	font-size: 1.4em;
}

.terapia__item {
	padding: 0.5em;
}

.terapia__item::marker {
	content: '»';
	color: var(--green);
}

.terapia__items {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}
@media only screen and (max-width: 767px) {
	.terapia{
		margin: 0 auto;
		width: 85%;
	}
	.terapia__p {
		font-size: 1.2em;
		text-align: left;
	}
	.terapia__lista {
		font-size: 1.2em;
	}
	
	.terapia__items {
		grid-template-columns: repeat(1, 1fr);
	}

}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.terapia__p {
		text-align: left;
	}
}

@media screen and (max-width: 300px) {
	.terapia__p{
		font-size: 1.1em;
	}

	.terapia__lista {
		font-size: 1em;
	}
}