.masaz__h3 {
	text-align: center;
	padding: 5em 0;
	font-size: 1.4em;
	width: 90%;
	margin: 0 auto;
}

.masaz__img {
	background-image: url('../../public/images/massage-lg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: top;
    background-attachment: fixed;
    height: 45vh;
    box-shadow:
    -50px -50px 0 -46px var(--green),
    50px 50px 0 -46px var(--green);
}

@media only screen and (max-width: 767px) {
	.masaz{
		margin: 0 auto;
		width: 90%;
	}
	.masaz__img {
		background-image: url('../../public/images/massage-sm.jpg');
		background-position: center;
		background-attachment:local;
		height: 30vh;
	}
	.masaz__h3 {
		font-size: 1.2em;
		text-align: left;
	}

}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.masaz__img {
		background-position: center;
		background-attachment:local;
	}
}

@media screen and (max-width: 300px) {
	.masaz__h3 {
		font-size: 1.1em;
	}
}