.offer__wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
}

.uslugi__wrapper {
	padding-top: 3em;
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
}

.cardOffer {
	margin-top: 3em;
	overflow: hidden;
	width: 90%;
	background-color: var(--offWhite);
}

.cardTitle{
	height: 80px;
	overflow: hidden
}

.cardTitleTwo{
	height: 80px;
	padding-top:35px;
	overflow: hidden
}

.cardText{
	height: 40px;
	overflow: hidden
}
.card-title {
	text-transform: uppercase;
	letter-spacing: 0.3rem;
	text-align: center;
	padding: 0.5em;
	font-weight: 400;
}

.img__offer {
	height: 45vh;
}

.img__offer:hover {
	transform: scale(1.1);
}

@media only screen and (max-width: 1023px) {
	.uslugi__wrapper {
		grid-template-columns: repeat(1, 1fr);
	}
	.cardOffer {
		width: 80%;
	}
	.img__offer {
		height: 30vh;
	}
}
@media only screen and (max-width: 300px) {
	.offer__wrapper {
		margin: 0 auto;
		width: 87%;
	}
	.img__offer {
		height: 25vh;
	}
	.card-title {
		font-size: 1em;
	}
	.card-text{
		font-size: 0.8em;
	}
}