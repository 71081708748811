.iglowanie__h3 {
	text-align: center;
	padding: 5em 0;
	font-size: 1.4em;
}

.iglowanie__img {
	background-image: url('../../public/images/iglowanie-lg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
    background-attachment: fixed;
    height: 45vh;
    box-shadow:
    -50px -50px 0 -46px var(--green),
    50px 50px 0 -46px var(--green);
}
@media only screen and (max-width: 767px) {
	.iglowanie{
		margin: 0 auto;
		width: 85%;
	}
	.iglowanie__img {
		background-image: url('../../public/images/iglowanie-sm.jpg');
		background-position: center;
		background-attachment:local;
		height: 30vh;
	}
	.iglowanie__h3 {
		font-size: 1.2em;
		text-align: left;
	}

}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.iglowanie__img {
		background-position: center;
		background-attachment:local;
	}
	.iglowanie__h3 {
		text-align: left;
	}
}

@media screen and (max-width: 300px) {
	.iglowanie__h3 {
		font-size: 1.1em;
	}
}