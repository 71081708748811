.form-group {
	width: 100%;
	margin-bottom: 2rem;
}
input,
textarea {
	width: 100%;
	font-size: 2rem;
	padding: 1.2rem;
	color: var(--white);
	background-color: var(--contactGrey);
	outline: none;
	border: none;
	border-radius: 8px;
	margin-top: 1rem;
}
textarea {
	min-height: 250px;
	resize: vertical;
}
button[type='submit'] {
	color: var(--white);
	background-color: var(--contactGrey);
	font-size: 2rem;
	display: inline-block;
	outline: none;
	border: none;
	padding: 1rem 4rem;
	border-radius: 8px;
	cursor: pointer;
}
button:hover {
	color: var(--grey);
}

@media only screen and (max-width: 300px) {
	input,
	textarea {
		font-size: 1.5rem;
	}
	button[type='submit'] {
		font-size: 1.5rem;
	}
}
