.wizyty__h3 {
	text-align: center;
	padding: 3em 0;
	color: var(--grey);
}

.wizyty__img {
	background-image: url('/src/assets/Image/reh_dom.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
	background-attachment: fixed;
	height: 45vh;
	box-shadow: -50px -50px 0 -46px var(--green), 50px 50px 0 -46px var(--green);
}

.wizyty__text {
	padding-top: 2em;
	font-size: 1.5em;
	text-align: justify;
}

.wizyty__lista {
	font-size: 1.4em;
}

.wizyty__item {
	padding: 0.5em;
}

.wizyty__item::marker {
	content: '»';
	color: var(--green);
}

@media only screen and (max-width: 767px) {
	.wizyty {
		margin: 0 auto;
		width: 85%;
	}
	.wizyty__img {
		background-position: center;
		background-attachment: local;
	}

	.wizyty__text {
		font-size: 1.2em;
		text-align: left;
	}

	.wizyty__lista {
		font-size: 1.2em;
	}
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.wizyty__img {
		background-position: center;
		background-attachment: local;
	}
}

@media screen and (max-width: 300px) {
	.wizyty__h3 {
		font-size: 1.1em;
	}
	.wizyty__text {
		font-size: 1em;
	}

	.wizyty__lista {
		font-size: 1em;
	}
}
