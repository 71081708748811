.footer-container {
	position: relative;
	left: 0;
	bottom: 0;
	width: 100%;
	overflow: auto;
}

.footer-text {
	display: flex;
	align-items: end;
	justify-content: end;
	bottom: 0;
	padding-right: 0.5rem;
}

@media screen and (max-width: 300px) {
	.footer-text {
		font-size: 1rem;
	}
}
