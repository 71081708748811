.usg__bold {
	color: var(--green);
}

.usg__p {
	font-size: 1.2em;
	margin: 2em 0 0.5em;
}

.usg__listaOne {
	font-size: 1.4em;
}

.usg__lista {
	font-size: 1.4em;
	height: 100%;
	margin-top: 1em;
	position: relative;
	border: 1px solid transparent;
	border-radius: 8px;
	transition: border 0.5s;
	z-index: 5;
}

.usg__lista:hover {
	border: 1px solid var(--green);
	z-index: 1;
}

.usg__lista::before {
	position: absolute;
	content: '';
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border: 1px solid var(--green);
	border-radius: 8px;
	opacity: 0;
}

.usg__lista:hover::before {
	animation: pulse 1s;
}

@keyframes pulse {
	from {
		transform: scale(1);
		opacity: 0.5;
	}

	to {
		transform: scale(1.4);
		opacity: 0;
	}
}

.usg__item {
	padding: 0.5em;
}

.usg__item::marker {
	content: '»';
	color: var(--green);
}

.usg__items {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
	gap: 20px;
}

.usg__h4 {
	padding: 1em;
	text-align: center;
	letter-spacing: 0.2em;
	text-transform: uppercase;
}

.usg__img {
	background-image: url('../../public/images/usg-lg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	height: 45vh;
	box-shadow: -50px -50px 0 -46px var(--green), 50px 50px 0 -46px var(--green);
	margin: 1em 0 2em;
}

.usg__icon {
	margin: 0 auto;
	width: 58%;
}

.usg__upperList {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	justify-content: center;
	align-items: center;
}

@media only screen and (max-width: 767px) {
	.usg {
		margin: 0 auto;
		width: 85%;
	}
	.usg__p {
		font-size: 1.2em;
		text-align: left;
	}
	.usg__lista {
		font-size: 1.2em;
	}

	.usg__items {
		grid-template-columns: repeat(1, 1fr);
	}
	.usg__upperList {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
	}
	.usg__img {
		background-image: url('../../public/images/usg-sm.jpg');
		background-position: center;
		background-attachment: local;
		height: 30vh;
	}
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.usg__img {
		background-position: center;
		background-attachment: local;
	}
}

@media screen and (max-width: 300px) {
	.usg__p {
		font-size: 1.1em;
	}
	.usg__h4 {
		font-size: 1.2em;
	}
	.usg__lista,
	.usg__listaOne {
		font-size: 1em;
	}
}
