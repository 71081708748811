* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	font-family: 'Barlow', sans-serif;
}

:root {
	--greyLight: rgb(228, 225, 225);
	--grey: rgb(73, 73, 73);
	--greyMedium: rgb(132, 132, 132);
	--contactGrey: rgba(160, 160, 160, 0.625);
	--offWhite: rgba(255, 255, 255, 0.653);
	--white: white;
	--green: rgb(93, 177, 86);
	--greenOpacity: rgba(94, 177, 86, 0.675);
}
html {
	scrollbar-width: normal;
	scrollbar-color: rgb(171, 171, 171) rgb(211, 211, 211);
}

html::-webkit-scrollbar {
	width: 1vw;
}

html::-webkit-scrollbar-thumb {
	background-color: rgb(195, 195, 195);
}

html::-webkit-scrollbar-thumb:hover {
	background-color: rgb(167, 167, 167);
}

html::-webkit-scrollbar-track {
	background-color: rgb(145, 145, 145);
}

html::-webkit-scrollbar-track:hover {
	background-color: rgb(124, 124, 124);
}

body {
	background-color: var(--white);
}
