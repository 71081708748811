.acl__h3 {
	text-align: justify;
	word-spacing:-2px;
	padding: 4em 0 3em;
	font-size: 1.4em;
}

.acl__img {
	background-image: url('../../public/images/squats-lg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
    background-attachment: fixed;
    height: 45vh;
    box-shadow:
    -50px -50px 0 -46px var(--green),
    50px 50px 0 -46px var(--green);
}

.acl__text{
    font-size: 1.4em;
    padding: 0.3em;
}

.acl__lista {
	font-size: 1.3em;
}

.acl__item {
	padding: 0.5em;
}

.acl__item::marker {
	content: '»';
	color: var(--green);
}

.acl__items{
    padding: 0.5em;
}

.acl__bold{
    font-weight: bold;
}

.acl__bg{
    background-color: transparent;
}

@media only screen and (max-width: 767px) {
	.acl{
		margin: 0 auto;
		width: 85%;
	}
	.acl__img {
		background-image: url('../../public/images/squats-sm.jpg');
		background-position: center;
		background-attachment:local;
		height: 30vh;
	}
	
	.acl__h3 {
		font-size: 1.2em;
		text-align: left;
	}

    .acl__text{
        font-size: 1.1em;
    }
    
    .acl__lista {
        font-size: 1em;
    }
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.acl__img {
		background-position: center;
		background-attachment:local;
	}
	.acl__h3 {
		text-align: left;
	}
}

@media screen and (max-width: 300px) {
	.acl__h3 {
		font-size: 1.1em;
	}

    .acl__text{
        font-size: 1em;
    }
    
}