.fala__h3 {
	text-align: left;
	padding-bottom: 1em;
}

.fala__img {
	background-image: url('../../public/images/fala-lg.jpg');
	background-size: cover;
	background-repeat: no-repeat;
	background-position: left;
	background-attachment: fixed;
	height: 45vh;
	box-shadow: -50px -50px 0 -46px var(--green), 50px 50px 0 -46px var(--green);
	margin-top: 4em;
	margin-bottom: 6em;
}
.fala__boxes {
	display: grid;
	justify-content: center;
	align-items: center;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 20px;
}

.fala__lista {
	font-size: 1.4em;
	height: 100%;
	padding-top: 1em;
	position: relative;
	border: 1px solid transparent;
	border-radius: 8px;
	transition: border 0.5s;
	z-index: 5;
}

.fala__lista:hover {
	border: 1px solid var(--green);
	z-index: 1;
}

.fala__lista::before {
	position: absolute;
	content: '';
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border: 1px solid var(--green);
	border-radius: 8px;
	opacity: 0;
}

.fala__lista:hover::before {
	animation: pulse 1s;
}

@keyframes pulse {
	from {
		transform: scale(1);
		opacity: 0.5;
	}

	to {
		transform: scale(1.2);
		opacity: 0;
	}
}

.fala__item {
	padding: 0.5em;
}

.fala__item::marker {
	content: '»';
	color: var(--green);
}

.fa-spinner,
.fa-times,
.fa-check {
	padding-right: 0.5em;
	font-size: 1.5em;
}

.fa-spinner,
.fa-check {
	color: var(--green);
}

@media only screen and (max-width: 767px) {
	.fala {
		margin: 0 auto;
		width: 90%;
	}
	.fala__img {
		background-image: url('../../public/images/fala-sm.jpg');
		background-position: center;
		background-attachment: local;
		height: 30vh;
	}
	.fala__h3 {
		font-size: 1.2em;
	}
	.fala__boxes {
		grid-template-columns: repeat(1, 1fr);
	}

	.fala__lista {
		font-size: 1.2em;
	}

	.fa-spinner,
	.fa-times,
	.fa-check {
		font-size: 1.2em;
	}
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.fala__img {
		background-position: center;
		background-attachment: local;
		height: 35vh;
	}
}

@media screen and (max-width: 300px) {
	.fala__h3 {
		font-size: 1.1em;
	}

	.fala__lista {
		font-size: 1em;
	}
}
