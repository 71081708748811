.info {
	width: 100%;
	background: linear-gradient(
		90deg,
		rgb(221, 220, 220) 0%,
		rgb(93, 166, 78) 100%
	);
}

.info-right {
	display: flex;
	padding-top: 0.5em;
	gap: 1em;
	margin-left: auto;
	color: var(--white);
	font-size: 1.2em;
}
.info-tel {
	padding-left: 1em;
}

.info-social {
	margin-left: auto;
	padding-right: 1em;
	font-size: 1.5em;
	text-decoration: none;
	color: var(--white);
}

.info-social:hover {
	color: var(--grey);
}

@media screen and (max-width: 768px) {
	.info {
		display: none;
	}
}
