.navbar {
	background: linear-gradient(
		90deg,
		rgb(221, 220, 220) 0%,
		rgb(93, 166, 78) 100%
	);
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	position: sticky;
	top: 0;
	z-index: 999;
}

.navbar-container {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
	max-width: 1500px;
}

.navbar-logo {
	display: flex;
	align-items: center;
	justify-content: center;
	padding:0.5em 0.1em 1.3em 0.1em;
	cursor: pointer;
	/* z-index: 11; */
}


.navbar-img{
	width: 60%;
}

.nav-menu {
	display: grid;
	grid-template-columns: repeat(6, auto);
	list-style: none;
	text-align: center;
	width: 60vw;
	justify-content: end;
	margin-right: 2rem;
}

.nav-item {
	height: 100px;
}

.nav-links {
	color: var(--white);
	display: flex;
	align-items: center;
	text-decoration: none;
	padding: 0.5rem 1rem;
	height: 100%;
}

.nav-links:hover {
	border-bottom: 4px solid rgb(93, 177, 86);
	transition: all 0.2s ease-out;
	color: var(--grey);
}

.burger-btn {
	position: fixed;
	top: 0;
	right: 10px;
	padding: 1em;
	width: 6em;
	height: 4em;
	background: none;
	border: 1px solid transparent;
	cursor: pointer;
	z-index: 1000;
	border-radius: 5%;
}
.burger-btn:hover .burger-btn__bars::after,
.burger-btn:hover .burger-btn__bars::before {
	width: 100%;
}
.burger-btn__box {
	position: relative;
}
.burger-btn__bars,
.burger-btn__bars::after {
	position: absolute;
	right: 0;
	height: 0.1em;
	content: '';
	background-color: #f3f3f3;
	transition: width 0.3s;
}
.burger-btn__bars {
	width: 100%;
}
.burger-btn__bars::after {
	top: 1em;
	width: 60%;
}

.menu-icon {
	display: none;
}

nav.dark {
	background-color: var(--greyMedium);
}

.toggler {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	z-index: 1;
}

.toggler p {
	font-size: 0.625rem;
	font-weight: 600;
}

.toggler--slider {
	width: 25px;
	height: 4px;
	background-color: var(--greyMedium);
	border-radius: 2rem;
	margin-inline: 7px;
	cursor: pointer;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	border: 1px solid transparent;
	box-sizing: content-box;
}

nav.dark .toggler--slider {
	border-color: var(--white);
	background-color: #f5f5f5;
}

.toggler--slider--circle {
	height: 8px;
	width: 8px;
	background-color: var(--white);
	border-radius: 50%;
}

nav.dark .toggler--slider--circle {
	background-color: var(--greyMedium);
}

.toggler--light {
	color: var(--white);
}

nav.dark .toggler--light {
	color:var(--white);
}

.toggler--dark {
	color: var(--greyMedium);
}

nav.dark .toggler-dark {
	color: var(--white);
}

nav.dark .toggler--slider {
	justify-content: flex-end;
}

section.dark {
	background-color: var(--greyMedium);
}
section.dark {
	background-color: var(--greyMedium);
}

@media screen and (max-width: 960px) {
	.burger-btn {
		height: 2em;
	}
	.nav-menu {
		position: fixed;
		display: flex;
		flex-direction: column;
		justify-content: center;
		width: 100%;
		top: 0;
		width: 100vw;
		height: 100vh;
		left: -100%;
		opacity: 1;
		transition: all 0.5s ease;
	}

	.nav-menu.active {
		background: linear-gradient(
			90deg,
			rgb(221, 220, 220) 0%,
			rgb(180, 180, 180) 100%
		);
		left: 0;
		opacity: 1;
		transition: all 0.5s ease;
		top: 0;
		z-index: 2;
	}

	.nav-links {
		display: flex;
		align-items: center;
		justify-content: space-around;
		text-align: center;
		width: 100%;
	}

	.nav-links:hover {
		color: #242424;
		border-radius: 0;
	}

	.navbar-logo {
		position: absolute;
		top: 0;
		left: 0;
		padding: 0.4em 0.1em 1.5em;
	}
	
	.navbar-img{
		width: 50%;
	}

	.menu-icon {
		position: fixed;
		display: block;
		position: absolute;
		top: 0;
		right: 0;
		transform: translate(-100%, 60%);
		font-size: 1.8rem;
		cursor: pointer;
		z-index: 10;
	}
	.toggler {
		padding-left: 2em;
	}
	ul{
		padding-left: 0;
	}
}

@media screen and (max-width: 768px) {
	.burger-btn {
		width: 4em;
	}
	.nav-links {
		font-size: 1.2em;
	}
	.nav-links:first-child {
		padding-top: 2.2em;
	}
	.navbar-logo {
		align-items: start;
		justify-content: start;
		padding: 1.8em 0.1em 0 1em;
	}	
	.navbar-img{
		position: relative;
		width: 140px;
		top: -15px;
		/* width: 35%; */
	}
}

@media screen and (min-width: 768px) and (max-width: 960px) {
	.nav-links {
		font-size: 2em;
	}
	.nav-menu {
		grid-gap: 2em;
	}
	.toggler p {
		font-size: 1rem;
	}
	.navbar-logo {
		align-items: start;
		justify-content: start;
		padding: 1.2em 0.1em 0.8em 2em;
	}	
	.navbar-img{
		width: 45%;
	}
}

@media screen and (max-width: 300px) {
	.burger-btn {
		width: 4em;
	}
	.toggler {
		padding-left: 0.1em;
		padding-bottom: 3em;
	}

	.toggler--slider {
		width: 15px;
		height: 2px;
		margin-inline: 2px;
	}
	.navbar-logo {
		align-items: start;
		justify-content: start;
		padding: 2.2em 0.1em 0 1em;
	}	
	.navbar-img{
		position: relative;
		width: 140px;
		top: -15px;
		/* width: 40%; */
	}

	.nav-links{
		font-size: 1em;
	}
}
